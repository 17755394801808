import axios from './api';
import { useCurrentUserStore } from '/entrypoints/stores/currentUserStore';

export default class BaseService {
  constructor(endpoint) {
    this.baseEndpoint = endpoint;
  }

  get endpoint() {
    const currentUserStore = useCurrentUserStore();
    const accountId = currentUserStore.user.accountId;
    return `/api/v1/accounts/${accountId}${this.baseEndpoint}`;
  }

  async getAll(params = {}) {
    return axios.get(this.endpoint, { params });
  }

  async get(id) {
    return axios.get(`${this.endpoint}/${id}`);
  }

  async create(data) {
    return axios.post(this.endpoint, data);
  }

  async update(id, data) {
    return axios.put(`${this.endpoint}/${id}`, data);
  }

  async delete(id) {
    return axios.delete(`${this.endpoint}/${id}`);
  }
}
